<template>
  <div
    :key="uid"
    :class="className"
  >
    <motion.div
      :id="uid"
      :class="
        useBorder
          ? 'v-progress-bar-meter v-progress-bar-meter--border'
          : 'v-progress-bar-meter'
      "
      :initial="{ scaleX: 0 }"
      :style="{ scaleX: percent / 100 }"
      :transition="{
        duration: 0.4,
        scaleX: { type: 'spring', visualDuration: 0.4, bounce: 0.5 }
      }"
    />
  </div>
</template>

<script setup lang="ts">
import { motion } from 'motion-v'

const {
  percent,
  theme = 'primary',
  useBorder = false
} = defineProps<{
  percent: number
  theme?: 'body' | 'popup' | 'primary' | 'secondary'
  useBorder?: boolean
}>()
const uid = useId()

const className = computed<string>(() => {
  let result = `v-progress-bar v-progress-bar--${theme}`

  if (useBorder) {
    result += ` v-progress-bar--border v-progress-bar-border--${theme}`
  }

  return result
})
</script>

<style lang="scss">
@use 'assets/variables';

.v-progress-bar {
  box-sizing: content-box;
  height: 16px;
  position: relative;
  border-radius: variables.$BorderRadius;
  padding: 0;
  border: 1px solid variables.$Mono100;
  overflow: hidden;

  &--border {
    padding-right: 2px;
  }

  .v-progress-bar-meter {
    display: block;
    border-radius: variables.$BorderRadius;
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;

    &--border {
      margin: 1px;
      height: calc(100% - 2px);
    }
  }
}

.v-progress-bar--body {
  background: variables.$Mono300;
  height: 2px;

  .v-progress-bar-meter {
    background-color: variables.$Background;
  }
}

.v-progress-bar--popup {
  background: variables.$PopupBackgroundOpaque;
  height: 2px;

  .v-progress-bar-meter {
    background-color: variables.$PopupColor;
  }
}

.v-progress-bar--primary {
  background: variables.$PrimaryBackgroundColorOpaq25;

  &.v-progress-bar-border--primary {
    border: 1px solid variables.$PrimaryBackgroundColor;
  }

  .v-progress-bar-meter {
    background-color: variables.$PrimaryBackgroundColor;
  }
}

.v-progress-bar--secondary {
  background: variables.$SecondaryBackgroundColorLighten70;

  &.v-progress-bar-border--secondary {
    border: 1px solid variables.$SecondaryBackgroundColor;
  }

  .v-progress-bar-meter {
    background-color: variables.$SecondaryBackgroundColor;
  }
}
</style>
